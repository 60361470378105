import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import {Layout} from '@components/layout/layout'
import { H1, H3, Paragph } from "@components/layout/style"
import {  MainContainer, Question,Response, RightContainer, StyledHero } from "../../components/oferta.style"


const StomatologiaDziecieca:React.FC = ({data}) => {
  return (<Layout data={data} prop={{
    canonicalUrl: 'https://www.mediroot.pl/oferta/stomatologia-dziecieca/',
    title: 'Stomatologia dziecięca | Mediroot',
    description: 'Mediroot ➤ Stomatolog Wągrowiec - Stomatologia zachowawcza ✔ Stomatologia dziecięca ✔ Ortodoncja ✔ Protetyka stomatologiczna ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
  }}>
    <StyledHero>
      <StaticImage
        src="../../assets/images/hero.jpg"
        placeholder="blurred"
        alt="Hero image"
        layout="fullWidth"/>
    </StyledHero>
    <MainContainer>
      <RightContainer>


        <H1>
          Stomatologia Dziecięca
        </H1>

        <Paragph>
        Dział stomatologi zajmujący się leczeniem zębów i schorzeń jamy ustnej najmłodszych pacjentów.
Nasi specjaliści są doskonale przygotowani pod względem psychologicznym do pracy z najmłodszym pacjentem.
Staramy się wytłumaczyć dziecku wszystkie procedury, pokazać urządzenia i oswoić z przestrzenią gabinetu. To ważne, aby dziecko czuło komfort oraz pełne zaufanie do stomatologa.
        </Paragph>

        <H3>
          Pytania
        </H3>

        <Paragph style={{textIndent:0}}>
          <Question>
            Czy mleczne zęby warto leczyć?
          </Question>
          <Response>
          Oczywiście, że tak. Od stanu zdrowia zębów mlecznych w dużym stopniu zależy stan przyszłych zębów stałych. Niektórzy  traktują usunięcie zęba mlecznego jako sposób na pozbycie się próchnicy. Jednak  przedwczesne usunięcie zębów mlecznych grozi później poważnymi wadami zgryzu. U dzieci wszystkie kości rosną dość intensywnie, również kości szczęk. Żeby proces wzrostowy przebiegał prawidłowo potrzebuje bodźców. Jednym z takich bodźców jest tkwiący w szczęce mleczny ząb, który „pracuje”, czyli gryzie pokarm. Idąc dalej tym tokiem rozumowania, im więcej zębów się usunie, tym mniejszą ilość bodźców będą miały kości do wzrostu. W efekcie, może dochodzić do nieprawidłowego wyrzynania się zębów stałych, które są większe od zębów mlecznych, może się okazać, że się w szczęce po prostu nie mieszczą. Rosną wtedy pod złym kątem albo na siebie zachodzą, nie dają miejsca dla pozostałych zębów. Zwiększa się wtedy szansa pojawienia się próchnicy, chorób przyzębia oraz wad ortodontycznych. Dlatego  należy  zęby mleczne leczyć,zwłaszcza ,że nieleczona próchnica może się przenieść na zęby stałe. W sytuacji gdyby próchnica zaatakowała dużą powierzchnię zęba lub objęła wiele zębów mlecznych, proces ten można powstrzymać, nie usuwając zęba – potrzebne jest wtedy lapisowanie, lub inaczej impregnacja zębiny.
          </Response>

          <Question>
            Co to jest tzw. „próchnica butelkowa”?
          </Question>
          <Response>
            Próchnica butelkowa dotyczy niemowląt i dzieci w wieku przedszkolnym, u których próchnica rozwija się na wielu zębach jednocześnie. Próchnica butelkowa występuje u dzieci karmionych słodkimi potrawami przez butelkę . Taki sposób podawania słodkich płynów wydłuża czas przebywania cukrów w jamie ustnej i w większym stopniu zakwasza środowisko jamy ustnej. Również zasypianie z butelką słodkiego napoju lub smoczkiem zanurzonym w syropie wywołuje tego typu próchnicę. U śpiącego dziecka z butelką w buzi dochodzi do zmniejszenia wydzielania się śliny, której rolą jest neutralizacja tego zakwaszenia. W związku z tym po wypiciu przez dziecko słodkiego napoju przed snem  należy  umyć zęby.. Poza tym nie należy dodatkowo dosładzać gotowych soków i napojów.
          </Response>

          <Question>
            Co to jest lakowanie i kiedy należy wykonać ten zabieg u dziecka?
          </Question>
          <Response>
          Lakowanie jest zabiegiem profilaktycznym, którego ideą jest ochrona bruzd zębów przed rozwojem próchnicy poprzez wypełnienie ich odpowiednim materiałem. Lakowanie wykonuje się najczęściej w zębach bocznych, których bruzdy są szczególnie głębokie, a przez to szczególnie narażone na próchnicę. Głębokie bruzdy są miejscami retencyjnymi dla płytki bakteryjnej, pokarmu, których oczyszczenie nawet najcieńszymi włosiami szczoteczki jest często niemożliwe. Zabieg wykonuje się jedynie w zębach całkowicie wyrzniętych i zdrowych, nietkniętych jeszcze próchnicą. Pokrycie lakiem zęba z próchnicą prowadziłoby do groźnych powikłań w postaci rozwoju próchnicy pod lakiem i przez dłuższy czas niewidocznej. Dlatego lakowanie należy wykonać niezwłocznie po wyrznięciu się pierwszych stałych zębów trzonowych tzn. około 6 roku życia dziecka, niekiedy wcześniej lub później.
          </Response>

          <Question>
          Czy tylko pierwsze ząbkowanie jest bolesne?
          </Question>
          <Response>
          Zarówno wyrzynanie zębów mlecznych, jak i stałych wiąże się z przykrymi dolegliwościami. Zwiększa się wydzielanie śliny, dziąsła stają się obrzmiałe, zaczerwienione, swędzące. Dziecko trze dziąsła rączkami, zabawkami i innymi przedmiotami, które uda mu się włożyć do buzi. Ząbkowaniu towarzyszy fizjologiczny lekki stan zapalny, który może się potęgować, w zależności od skłonności. Z reguły wyrzynanie zębów stałych sprawia mniejsze dolegliwości bólowe.
          </Response>

          <Question>
          Jak można zmniejszyć dolegliwości bólowe w trakcie ząbkowania?
          </Question>
          <Response>
          Ząbkowanie nie jest chorobą, a jedynie fizjologicznym procesem dotyczącym każdego dziecka. To prawda, że występujące dolegliwości można złagodzić, by wyrzynanie się zębów było mniej przykre. Najprościej dać dziecku w tym okresie specjalny, twardy gryzaczek, którym trze dziąsła łagodząc swędzenie. Taki sam efekt daje wkładanie do buzi twardych skórek od pieczywa lub sucharków. Stan zapalny dziąseł zmniejsza przemywanie naparem z rumianku. Zaparzamy kwiat rumianku, moczymy w nim kawałek sterylnej gazy, owijamy nią palec i delikatnie pocieramy dziąsła pamiętając o idealnej higienie. Przy objawach bardzo nasilonych lekarz może zalecić smarowanie dziąseł specjalnymi żelami lub maściami, jeśli dziecko nie jest uczulone na ich składniki. W aptekach dostępne są takie preparaty, które zmniejszają miejscowe dolegliwości. Mają one działanie znieczulające miejscowo i odkażające, często w sposób skojarzony – zależnie od zawartych w nich składników. Jeśli objawy są nasilone, połączone z gorączką, niezbędna jest porada lekarza pediatry lub stomatologa.
          </Response>

          <Question>
          Jak postępować z zębami niemowlęcia, by później mogły być zdrowe?
          </Question>
          <Response>
          O higienę jamy ustnej należy dbać od pierwszych miesięcy życia. Niestety, tylko nieliczne matki wiedzą, że po każdym karmieniu (również piersią) należy delikatnie czyścić jamę ustną dziecka mokrą gazą, nawiniętą na palec lub szpatułkę. Po pojawieniu się  pierwszego ząbka należy czyścić już specjalną pastą z obniżoną dawką fluoru.
          </Response>

          <Question>
          Kiedy zaczynają wyrzynać się pierwsze ząbki?
          </Question>
          <Response>
          Z reguły pierwsze ząbki mleczne zaczynają się wyrzynać około 6-tego miesiąca życia dziecka. Najpierw pojawiają się dwa dolne siekacze, a w kilka miesięcy później dwa górne. Nie jest to jednak reguła. Termin w dużej mierze jest uwarunkowany genetycznie, ale zależy także od tego, czy dziecko jest drobne, wątłe, i czy często choruje. Normą fizjologiczną, w którym powinny zacząć wyrzynać się zęby mleczne jest okres od 4 do 11 miesiąca życia dziecka. Niektóre niemowlaki zaczynają ząbkować dopiero pod koniec pierwszego roku życia i jest to jeszcze zgodne z normą fizjologiczną. Dla stanu uzębienia gorsze jest zbyt wczesne ząbkowanie w 3-4 miesiącu. Zęby są wówczas słabiej zmineralizowane i łatwiej ulegają próchnicy.
          </Response>

          <Question>
          Z czego wynika bolesność dziąseł w czasie ząbkowania?
          </Question>
          <Response>
          Bolesność wyrzynania się pierwszych zębów wynika po części z faktu, że zawiązki zębów mlecznych usytuowane są głęboko w kości szczęki i żuchwy i w czasie wyrzynania szybko przesuwają się do pozycji końcowej. Jednak najważniejszą przyczyną bólu jest to, że otwór kostny, przez który przedostaje się na zewnątrz zawiązek zęba ma otwór mniejszy, niż średnica korony zęba, czemu towarzyszy załamanie się tkanki łącznej i kostnej. Tworzy się miejscowy stan zapalny, dziąsła są obrzęknięte i zaczerwienione, bolą i swędzą. Dochodzi również do zwiększenia działania gruczołów ślinowych (ślinotok). W okresie ząbkowania dziecko jest nadpobudliwe, często płacze, jest apatyczne, nie chce jeść, ma problemy ze spokojnym snem, często budzi się w nocy. Sporadycznie może pojawić się gorączka. Chociaż u niektórych dzieci nie występują prawie żadne objawy. Nasilenie dolegliwości zależy m.in. od ogólnej odporności dziecka, oraz innych chorób towarzyszących. Rodzice powinni zwracać szczególną uwagę w tym okresie, by ich zdenerwowanie i niepokój nie udzieliły się dziecku i nie spowodowały większego pobudzenia. Naturalny proces ząbkowania staje się poważnym przeżyciem całej rodziny. Należy pamiętać, że gorączka i ból, zawsze wymaga konsultacji z lekarzem, gdyż podobne objawy mogą towarzyszyć innym chorobom.
          </Response>

          <Question>
          Jak postępować z zębami niemowlęcia, by później mogły być zdrowe?
          </Question>
          <Response>
          O higienę jamy ustnej należy dbać od pierwszych miesięcy życia. Niestety, tylko nieliczne matki wiedzą, że po każdym karmieniu (również piersią) należy delikatnie czyścić jamę ustną dziecka mokrą gazą, nawiniętą na palec lub szpatułkę. Ząbki, po pojawieniu się całej korony, też należy ostrożnie czyścić.
          </Response>

          <Question>
          Kiedy zaczynają wyrzynać się pierwsze ząbki?
          </Question>
          <Response>
          Z reguły pierwsze ząbki mleczne zaczynają się wyrzynać około 6-tego miesiąca życia dziecka. Najpierw pojawiają się dwa dolne siekacze, a w kilka miesięcy później dwa górne. Nie jest to jednak reguła. Termin w dużej mierze jest uwarunkowany genetycznie, ale zależy także od tego, czy dziecko jest drobne, wątłe, i czy często choruje. Normą fizjologiczną, w którym powinny zacząć wyrzynać się zęby mleczne jest okres od 4 do 11 miesiąca życia dziecka. Niektóre niemowlaki zaczynają ząbkować dopiero pod koniec pierwszego roku życia i jest to jeszcze zgodne z normą fizjologiczną. Dla stanu uzębienia gorsze jest zbyt wczesne ząbkowanie w 3-4 miesiącu. Zęby są wówczas słabiej zmineralizowane i łatwiej ulegają próchnicy.
          </Response>

          <Question>
          Z czego wynika bolesność dziąseł w czasie ząbkowania?
          </Question>
          <Response>
          Bolesność wyrzynania się pierwszych zębów wynika po części z faktu, że zawiązki zębów mlecznych usytuowane są głęboko w kości szczęki i żuchwy i w czasie wyrzynania szybko przesuwają się do pozycji końcowej. Jednak najważniejszą przyczyną bólu jest to, że otwór kostny, przez który przedostaje się na zewnątrz zawiązek zęba ma otwór mniejszy, niż średnica korony zęba, czemu towarzyszy załamanie się tkanki łącznej i kostnej. Tworzy się miejscowy stan zapalny, dziąsła są obrzęknięte i zaczerwienione, bolą i swędzą. Dochodzi również do zwiększenia działania gruczołów ślinowych (ślinotok). W okresie ząbkowania dziecko jest nadpobudliwe, często płacze, jest apatyczne, nie chce jeść, ma problemy ze spokojnym snem, często budzi się w nocy. Sporadycznie może pojawić się gorączka. Chociaż u niektórych dzieci nie występują prawie żadne objawy. Nasilenie dolegliwości zależy m.in. od ogólnej odporności dziecka, oraz innych chorób towarzyszących. Rodzice powinni zwracać szczególną uwagę w tym okresie, by ich zdenerwowanie i niepokój nie udzieliły się dziecku i nie spowodowały większego pobudzenia. Naturalny proces ząbkowania staje się poważnym przeżyciem całej rodziny. Należy pamiętać, że gorączka i ból, zawsze wymaga konsultacji z lekarzem, gdyż podobne objawy mogą towarzyszyć innym chorobom.
          </Response>
        </Paragph>
      </RightContainer>
    </MainContainer>


  </Layout>);
};

export default StomatologiaDziecieca;


export const query = graphql`
  query {
    logo: file(relativePath: { eq: "images/logo3.png" }) {
      childImageSharp {
        fluid( maxHeight: 92) {
          src
          sizes
          srcSet
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
  `
